@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
}

@layer base {
    [type='text'],
    [type='url'],
    [type='email'],
    [type='password'],
    [type='number'],
    [type='date'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='week'],
    [multiple],
    textarea,
    select{
        @apply shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5;
    }
    [type='dropdown']{
        @apply text-black bg-gray-50 hover:bg-blue-700 hover:text-white focus:ring-1 focus:outline-none focus:ring-black font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center
    }
    [type='button']{
        @apply text-white bg-blue-700 hover:bg-blue-900 hover:text-white focus:ring-1 focus:outline-none focus:ring-black font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center
    }
    [type='label']{
        @apply block mb-2 text-base font-medium text-gray-900
    }
    [type='radio']
    [type='checkbox']{
        @apply rounded border-gray-300 text-blue-700 focus:ring-blue-600
    }
    [type='textarea']{
        @apply block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500
    }
    [type='thead']{
        @apply rounded-sm
    }
    /* [type='thead']{
        @apply text-md text-gray-700 uppercase bg-gray-700 text-gray-400
    }
    [type='tbody']{
        @apply bg-white border-b bg-gray-800 border-gray-700
    } */
}


.ag-header-cell-label {
    justify-content: center; /* Centers the text horizontally */
    text-align: center; /* Ensures alignment */
  }